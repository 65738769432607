import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import setupQueryClient from "./scripts/react-query/setupQueryClient";
import styles from "./App.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import AuthenticationProvider from "./hooks/useAuthentication";
import { DisabledOnInteracting } from "./hooks/useIsApiInteracting";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Index from "./pages/Index";
import ErrorNotifications from "./components/ErrorNotifications/ErrorNotifications";
import Error401 from "./pages/Errors/401/Index";
import TwitchSignIn from "./pages/Twitch/SignIn";
import YouTubeSignIn from "./pages/YouTube/SignIn";
import IndexEvents from "./pages/Events/Index";
import ViewEvent from "./pages/Events/View";
import EventParticipant from "./pages/Events/Participant/View";
import EventParticipantWidget from "./pages/Events/Participant/Widget";

const queryClient = setupQueryClient();

const App = () => {
  const isStreamerMode = window.location.search.includes('streamerMode=true') &&
    window.location.search.includes('hideWidget=true');

  return (
    <div className={styles.app} style={{ backgroundColor: isStreamerMode ? 'transparent' : undefined }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthenticationProvider>
            <DisabledOnInteracting>
              <Header />
              <ErrorNotifications />
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/401" element={<Error401 />} />
                <Route path="/twitch/sign_in" element={<TwitchSignIn />} />
                <Route path="/youtube/sign_in" element={<YouTubeSignIn />} />
                <Route path="/events" element={<IndexEvents />} />
                <Route path="/events/:nameBase64" element={<ViewEvent />} />
                <Route path="/events/:nameBase64/:participantId" element={<EventParticipant />} />
                <Route path="/events/:nameBase64/widget" element={<EventParticipantWidget />} />
              </Routes>
              <Footer />
            </DisabledOnInteracting>
          </AuthenticationProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
};

export default App;
