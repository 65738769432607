import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import TwitchLogoLight from "../../../assets/youtube/brand-full-color-dark-logo/youtube_full_color_dark_logo/digital_and_tv/yt_logo_rgb_dark.png";
import TwitchLogoDark from "../../../assets/youtube/brand-full-color-light-logo/youtube_full_color_light_logo/digital_and_tv/yt_logo_rgb_light.png";

const SignUp = ({ children, eventNameBase64, signIn, signUp, ...props}) => {
  const twitchSignUpUrl = useMemo(() => {
    const { REACT_APP_API_ROOT } = process.env;
    const url = new URL(`${REACT_APP_API_ROOT || "localhost:3100"}/auth/youtube/participant`);

    if (!signIn && eventNameBase64) {
      url.searchParams.set("eventName", eventNameBase64);
    }

    return url.toString();
  }, [signIn, eventNameBase64]);

  return (
    <Button as="a" href={twitchSignUpUrl} {...props}>
      {(signIn || signUp) && (
        <span className="ml-2">
          {signIn && "Sign in with "}
          {signUp && "Sign up with "}
        </span>
      )}
      <Image
        src={props.variant === "dark" ? TwitchLogoLight : TwitchLogoDark}
        style={{ height: 30 }}
      />
    </Button>
  );
};

export default SignUp;
