import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useAuthentication } from "../../hooks/useAuthentication";
import styles from "./CurrentUserBadge.module.scss";

const CurrentUser = ({ props }) => {
  const { currentUser } = useAuthentication();

  if (!currentUser?.participant) {
    return <div hidden />;
  }

  const { displayName, profilePictureUrl } = currentUser.participant;

  return (
    <div {...props} className={styles.profileWrapper}>
      <Image src={profilePictureUrl} className={styles.profileImage} />
      <Button
        as={Link}
        variant="link"
        className={styles.profileName}
        to="/"
      >
        {displayName}
      </Button>
    </div>
  );
};

export default CurrentUser;
