import React from "react";
import { useAuthentication } from "../../hooks/useAuthentication";
import Loading from "../Loading/Loading";
import ErrorPage401 from "../../pages/Errors/401/Index";

const RequiresAuthentication = ({ children }) => {
  const { currentUser, isLoading } = useAuthentication();

  if (currentUser) {
    return children;
  }

  if (isLoading ) {
    return <Loading forceDisplay />;
  }

  return <ErrorPage401 />;
};

export default RequiresAuthentication;
