import React, { useState, useCallback } from "react";
import { useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuthentication } from "../../../hooks/useAuthentication";
import PSNSearch from "./Search";

const PSNSignUp = ({ children, ...props }) => {
  const { currentUser: { participant } = {} } = useAuthentication();

  const { mutateAsync, isLoading } = useMutation("updateParticipant");

  const [showModal, setShowModal] = useState(false);

  const handleClose = useCallback(() => setShowModal(false), []);
  const onSelectPsnUser = useCallback(async (psnUser) => {
    await mutateAsync({
      participant: { id: participant.id, psnUser },
      me: true,
    });

    setShowModal(false);
  }, [mutateAsync, participant.id]);

  return (
    <fieldset disabled={isLoading} {...props}>
      <Button
        variant="dark"
        disabled={participant?.hasPsn || !participant?.id}
        onClick={() => setShowModal(true)}
      >
        {children || "Find Your PSN Handle"}
        {participant?.hasPsn && <FontAwesomeIcon className="pl-1" icon={faCheckCircle} />}
      </Button>
      <Modal show={showModal}>
        <Modal.Body>
          <PSNSearch onSelectPsnUser={onSelectPsnUser} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </fieldset>
  );
};

export default PSNSignUp;
