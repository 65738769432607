import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuthentication } from "../../../hooks/useAuthentication";

const { REACT_APP_API_ROOT } = process.env;

const XboxLiveSignUp = ({ children, ...props}) => {
  const { currentUser: { participant } = {} } = useAuthentication();

  return (
    <Button
      variant="success"
      as="a"
      href={`${REACT_APP_API_ROOT}/auth/xboxlive?participantId=${participant?.id}`}
      disabled={participant?.hasXboxLive || !participant?.id}
      {...props}
    >
      {children || "Sign in to XBox Live"}
      {participant?.hasXboxLive && <FontAwesomeIcon className="pl-1" icon={faCheckCircle} />}
    </Button>
  );
};

export default XboxLiveSignUp;
