import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import collect from "collect.js";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import RequiresAuthentication from "../components/Unauthorised/RequiresAuthentication";
import { useAuthentication } from "../hooks/useAuthentication";
import XboxLiveSignUp from "../components/Participants/XboxLive/SignUp";
import PSNLiveSignUp from "../components/Participants/PSN/SignUp";
import ParticipantsForm from "../components/Participants/Form";
import ParticipantLeaderboardCard from "../components/Participants/Leaderboard/Card";
import styles from "./Index.module.scss";

const Index = () => {
  const { currentUser: { participant } = {} } = useAuthentication();

  const eventName = useMemo(
    () => collect(participant?.eventNames).last(),
    [participant?.eventNames],
  );

  return (
    <RequiresAuthentication>
      <Jumbotron className="bg-dark">
        <Container>
          <h1 className="pb-4">Trophy Case Race!</h1>
          <div>Welcome {participant?.displayName}, let's get you started!</div>
          <hr />
        </Container>
      </Jumbotron>
      <Container>
        {eventName && (
          <>
            <ParticipantLeaderboardCard
              className="mb-4"
              participant={participant}
              eventName={eventName}
            >
              <Button
                as={Link}
                className={styles.participantLeaderboardCardWidgetButton}
                to={`/events/${encodeURIComponent(eventName)}/widget?streamerMode=true`}
              >
                Stream Widget
              </Button>
            </ParticipantLeaderboardCard>
          </>
        )}
        <Row>
          <Col>
            <XboxLiveSignUp />
            <PSNLiveSignUp className="ml-2 d-inline" />
          </Col>
        </Row>
        <hr className="bg-light" />
        <ParticipantsForm participant={participant} />
      </Container>
    </RequiresAuthentication>
  );
}

export default Index;
