import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import collect from "collect.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faAward } from "@fortawesome/free-solid-svg-icons";
import Card from 'react-bootstrap/Card';
import styles from "./Card.module.scss"

const ParticipantLeaderboardCard = ({ className, eventName, participant, hideImage, children, ...args }) => {
  const { data: { leaderboard } = {} } = useQuery(
    ["event", eventName, "leaderboard"],
  );

  const leaderboardParticipant = useMemo(
    () => collect(leaderboard).firstWhere("id", "===", participant?.id),
    [participant?.id, leaderboard],
  );

  const leaderboardPosition = useMemo(
    () => leaderboard?.findIndex(({ id }) => id === leaderboardParticipant?.id) + 1,
    [leaderboardParticipant?.id, leaderboard],
  );

  if (!leaderboardParticipant && !participant) {
    return <div hidden />;
  }

  const {
    id,
    name,
    displayName,
    profilePictureUrl,
    score = 0,
    psnTrophies,
    xboxLiveAchievements,
  } = leaderboardParticipant || participant;

  return (
    <Card className={`${className} bg-dark`} {...args}>
      {!hideImage && profilePictureUrl && (
        <Card.Img
          className={styles.cardImage}
          variant="top"
          src={profilePictureUrl}
          style={{
            backgroundUrl: `url("${profilePictureUrl}")`,
          }}
        />
      )}
      <Card.Body className="text-left">
        <h1 className="float-left pr-4">
          {leaderboardPosition || "?"}
        </h1>
        <Card.Title>
          <Link to={`/events/${encodeURIComponent(eventName)}/${id}`} className="text-light">
            {name || displayName}
          </Link>
          <small className="pl-2 text-muted">
            {score}pts
          </small>
        </Card.Title>
        <Card.Text className="text-muted">
          <FontAwesomeIcon icon={faTrophy} />
          <span> {psnTrophies?.length ?? 0} trophies</span>
          <span className="p-1" />
          <FontAwesomeIcon icon={faAward} />
          <span> {xboxLiveAchievements?.length ?? 0} achievements</span>
        </Card.Text>
      </Card.Body>
      {children && children}
    </Card>
  );
};

export default ParticipantLeaderboardCard;
