import React, { useEffect, useCallback, useMemo, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useMutation } from "react-query";
import Loading from "../Loading/Loading";
import FormBadge from "../Form/Badge/Badge";

const ParticipantsForm = ({ participant: originalParticipant, onSubmit, submitText }) => {
  const [participant, setParticipant] = useReducer(
    (accumualator, currentValue) => ({ ...accumualator, ...currentValue }),
    originalParticipant,
  );

  useEffect(
    () => setParticipant(originalParticipant),
    [originalParticipant],
  );

  const emailIsValid = useMemo(
    // eslint-disable-next-line no-useless-escape
    () => !!participant?.email?.match(/^\s*[\w\-\+_]+(?:\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(?:\.[\w\-\+_]+)*\s*$/),
    [participant?.email]
  );

  const emailIsFromTwitch = useMemo(
    () => participant?.twitchId && emailIsValid,
    [participant?.twitchId, emailIsValid],
  );

  const emailIsFromYouTube = useMemo(
    () => participant?.youtubeName && emailIsValid,
    [participant?.youtubeName, emailIsValid],
  )

  const isValid = emailIsValid && !emailIsFromTwitch && !emailIsFromYouTube;

  const { mutateAsync, isLoading } = useMutation("updateParticipant");
  const {
    isLoading: isLoadingApiToken,
    mutateAsync: mutateApiToken,
  } = useMutation("updateParticipantApiToken");

  const onSubmitForm = useCallback(
    async formEvent => {
      formEvent.preventDefault();
      formEvent.stopPropagation();

      await mutateAsync({
        participant: { id: participant.id, email: participant.email },
        me: true,
      });

      onSubmit?.(participant);
    },
    [mutateAsync, participant, onSubmit],
  );

  const onUpdateApiTokenHandler = useCallback(
    () => mutateApiToken({ participant }),
    [mutateApiToken, participant],
  );

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        <fieldset disabled={!participant || isLoading || isLoadingApiToken}>
          <Form.Row>
            <Form.Group as={Col} xs={12} controlId="participantEmail">
              <Form.Label>
                Participant Email
                <FormBadge required populated={emailIsValid} />
              </Form.Label>
              <Form.Control
                placeholder="Enter Participant Email"
                type="email"
                onChange={({ target }) => setParticipant({ email: target.value })}
                defaultValue={participant?.email}
                readOnly={emailIsFromTwitch || emailIsFromYouTube}
                required
              />
              {emailIsFromTwitch && (
                <Form.Text className="text-muted">
                  Cannot change email address when logged in through Twitch.
                </Form.Text>
              )}
              {emailIsFromYouTube && (
                <Form.Text className="text-muted">
                  Cannot change email address when logged in through YouTube.
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={12} controlId="apiToken">
              <Form.Label>API Key</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  defaultValue={participant?.apiToken}
                  readOnly
                />
              <InputGroup.Append>
                <Button variant="warning" onClick={onUpdateApiTokenHandler}>
                Regenerate
                </Button>
              </InputGroup.Append>
              </InputGroup>
              <Form.Text className="text-muted">
                This is automatically generated, and can be used for Stream Widgets during events.
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Col>
              <Button variant="primary" type="submit" disabled={!isValid}>{submitText || "Update Details"}</Button>
            </Col>
          </Form.Row>
        </fieldset>
      </Form>
    </>
  );
};

export default ParticipantsForm;
