import { useMemo } from "react";
import base64 from "base-64";
import { useAuthentication } from "../useAuthentication";

const useEventName = () => {
  const { currentUser: { participant } = {} } = useAuthentication();

  const eventNameBase64 = window.location.pathname
    .replace(/^\/events\/|\//, "")
    .replace(/\/.+/, "");

  const isEventNameEncoded = useMemo(
    () => eventNameBase64 && !!/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/.exec(eventNameBase64),
    [eventNameBase64],
  );

  const eventName = useMemo(
    () => decodeURIComponent(
      isEventNameEncoded ? base64.decode(eventNameBase64) : eventNameBase64
    ),
    [isEventNameEncoded, eventNameBase64],
  );

  const isParticipantInEvent = useMemo(
    () => !!participant?.eventNames?.includes(eventName),
    [participant?.eventNames, eventName],
  );

  return {
    eventName,
    eventNameBase64,
    canJoinEvent: isEventNameEncoded && !isParticipantInEvent,
  };
};

export default useEventName;
