import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faAward } from "@fortawesome/free-solid-svg-icons";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useQuery } from "react-query";
import collect from "collect.js";
import useEventName from "../../hooks/Events/useEventName";
import ParticipantLeaderboardCard from "../../components/Participants/Leaderboard/Card";

const TOP_LEADERS_COUNT = 3;

const ViewEvent = () => {
  const { eventName } = useEventName();

  const { data: { leaderboard } = {} } = useQuery(
    ["event", eventName, "leaderboard"],
  );

  const topLeaders = useMemo(() => collect(leaderboard).take(TOP_LEADERS_COUNT), [leaderboard]);
  const otherLeaders = useMemo(() => collect(leaderboard).skip(TOP_LEADERS_COUNT), [leaderboard]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Leaderboard / {eventName}</h1>
        </Container>
      </Jumbotron>
      <Container>
        <Row>
          <Col xs={12} className="pb-2">
            <ParticipantLeaderboardCard
              eventName={eventName}
              participant={topLeaders.get(0)}
            />
          </Col>
          <Col xs={12} md={6}>
            <ParticipantLeaderboardCard
              eventName={eventName}
              participant={topLeaders.get(1)}
            />
          </Col>
          <Col xs={12} md={6}>
            <ParticipantLeaderboardCard
              eventName={eventName}
              participant={topLeaders.get(2)}
            />
          </Col>
        </Row>
        {otherLeaders.isNotEmpty() && (
          <div className="pt-2">
            <hr className="text-light" />
            <Table variant="dark" striped borderless responsive hover>
              <tbody>
                {otherLeaders.map((row, index) => (
                  <tr key={row.id} >
                    <td>{index + 1 + TOP_LEADERS_COUNT}</td>
                    <td>
                      <Link to={`/events/${encodeURIComponent(eventName)}/${row.id}`} className="text-light font-weight-bold">
                        {row.name || row.displayName}
                      </Link>
                    </td>
                    <td>
                      <FontAwesomeIcon icon={faTrophy} className="text-muted" />
                      <span> {row.psnTrophies?.length ?? 0}</span>
                    </td>
                    <td>
                      <FontAwesomeIcon icon={faAward} className="text-muted" />
                      <span> {row.xboxLiveAchievements?.length ?? 0}</span>
                    </td>
                    <td>
                      {row.score}pts
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </>
  );
};

export default ViewEvent;
