import { useMemo, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCopy, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuthentication } from "./useAuthentication";

export const useStreamerMode = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const params = useMemo(() => {
    const streamerMode = Boolean(urlSearchParams.get('streamerMode'));
    const hideWidget = Boolean(urlSearchParams.get('hideWidget'));
    const backgroundColour = urlSearchParams.get('bgColour') || 'white';
    const borderColour = urlSearchParams.get('borderColour') || 'transparent';
    const refreshRateMs = Number(urlSearchParams.get('refreshRateMs') || (60 * 1000));
    const fontFamily = urlSearchParams.get('fontFamily') || '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
    const roundness = Number(urlSearchParams.get('roundness'));
    const colours = (urlSearchParams.get('colours') || '').split(',');

    return {
      streamerMode,
      hideWidget,
      backgroundColour,
      borderColour,
      refreshRateMs,
      fontFamily,
      roundness,
      colours,
    };
  }, [urlSearchParams]);

  const setStreamerMode = useCallback((value, { url }) => {
    if (value) {
      urlSearchParams.set('streamerMode', value);
    } else {
      urlSearchParams.delete('streamerMode');
    }

    navigate(`${url || pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate]);

  const setHideWidget = useCallback((value) => {
    if (value) {
      urlSearchParams.set('hideWidget', value);
    } else {
      urlSearchParams.delete('hideWidget');
    }

    navigate(`${pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate]);

  const setRefreshRateMs = useCallback((value) => {
    urlSearchParams.set('refreshRateMs', Number(value));

    navigate(`${pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate]);

  const setFontFamily = useCallback((value) => {
    urlSearchParams.set('fontFamily', value);

    navigate(`${pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate]);

  const setRoundness = useCallback((value) => {
    urlSearchParams.set('roundness', value);

    navigate(`${pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate]);

  const setColour = useCallback((index, value) => {
    const colours = [...params.colours];
    colours[index] = value;
    urlSearchParams.set('colours', colours.join(','));

    navigate(`${pathname}?${urlSearchParams}`);
  }, [urlSearchParams, pathname, navigate, params.colours]);

  return {
    ...params,
    setStreamerMode,
    setHideWidget,
    setRefreshRateMs,
    setFontFamily,
    setRoundness,
    setColour,
  };
};

export const StreamerModeButton = ({ url, ...props }) => {
  const { streamerMode, setStreamerMode } = useStreamerMode();

  const toggleStreamerMode = useCallback(
    () => setStreamerMode(!streamerMode, { url }),
    [setStreamerMode, streamerMode, url]
  );

  return (
    <Button onClick={toggleStreamerMode} {...props}>
      <FontAwesomeIcon icon={streamerMode ? faEye : faEyeSlash} />
      <span className="pl-1">Streamer Mode?</span>
    </Button>
  );
}

export const StreamerModeWidget = ({
  showRoundness,
  colours: coloursFromProps,
  hideStreamerModeToggle,
  ...props
}) => {
  const {
    streamerMode,
    hideWidget,
    setHideWidget,
    refreshRateMs,
    setRefreshRateMs,
    fontFamily,
    setFontFamily,
    roundness,
    setRoundness,
    colours,
    setColour,
  } = useStreamerMode();
  const { currentUser } = useAuthentication();
  const { pathname, search } = useLocation();

  const apiToken = currentUser?.participant?.apiToken;

  const url = useMemo(
    () => `${window.location.origin}${pathname}${search}&hideWidget=true&apiKey=${apiToken}`,
    [pathname, search, apiToken]
  );
  const copyUrlToClipboard = useCallback(
    async () => navigator.clipboard.writeText(url),
    [url]
  );

  if (!streamerMode || hideWidget) {
    return <div hidden />;
  }

  return (
    <div {...props} className="bg-primary m-4 rounded fixed-bottom text-left overflow-hidden" style={{ width: "500px" }}>
      <Form>
        <Form.Row>
          <Form.Group as={Col} xs={12} controlId="explaination" className="bg-dark">
            <div className="pt-2 pl-2 pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
              <Form.Label className="d-inline pl-2">
                This web page can be embedded into your stream as a widget
              </Form.Label>
              <hr className="bg-light" />
              <Form.Text>
                Once you've finished customising the widget copy the <i>signed URL for streaming</i>, you can use that URL to embed the widget in your stream setup. The background will become transparent, and the contents will scale to the width of the widget in your stream setup.
              </Form.Text>
            </div>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={showRoundness ? 6 : 12} controlId="refreshRateMs">
            <Form.Label className="pl-2 pt-2 text-light">
              Refresh Rate (ms)
            </Form.Label>
            <Form.Control
              className="rounded-0"
              placeholder="seconds between each refresh of points"
              type="number"
              onChange={({ target }) => setRefreshRateMs(target.value)}
              defaultValue={refreshRateMs}
            />
          </Form.Group>
          {showRoundness && (
            <Form.Group as={Col} xs={12} md={6} controlId="roundness">
              <Form.Label className="pl-2 pt-2 text-light">
                Roundness of Bars in Chart
              </Form.Label>
              <Form.Control
                className="rounded-0"
                placeholder="normalised roundness of bars in chart"
                type="number"
                min="0"
                max="512"
                step="10"
                onChange={({ target }) => setRoundness(target.value)}
                defaultValue={roundness}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} xs={12} controlId="fontFamily">
            <Form.Label className="pl-2 pt-2 text-light">
              Font Family
            </Form.Label>
            <Form.Control
              className="rounded-0"
              placeholder="font family, as you'd find in CSS"
              type="string"
              onChange={({ target }) => setFontFamily(target.value)}
              defaultValue={fontFamily}
            />
          </Form.Group>
          {(coloursFromProps || []).map(({ text, colour }, index) => (
            <Form.Group key={index} as={Col} xs={12} md={6} controlId={`colour${index}`}>
              <Form.Label className="pl-2 pt-2 text-light">
                Colour: {text}
              </Form.Label>
              <Form.Control
                className="rounded-0"
                placeholder="colour, as your'd find in CSS"
                type="color"
                onChange={({ target }) => setColour(index, target.value)}
                value={colours[index] || colour || "#000000"}
              />
            </Form.Group>
          ))}
          <Form.Group as={Col} xs={12} controlId="url">
            <Form.Label className="pl-2 text-light">
              <span>Signed URL for Streaming</span>
              {
                apiToken ? (
                  <Badge variant="warning" className="ml-2">
                    do not share!
                  </Badge>
                ) : (
                  <Badge as={Link} to={`/`} variant="secondary" className="ml-2">
                    you need to create an API key first
                  </Badge>
                )
              }
            </Form.Label>
            <InputGroup>
              <Form.Control className="rounded-0" value={url} readOnly disabled={!apiToken} />
              <InputGroup.Append>
                <Button variant="secondary" onClick={copyUrlToClipboard} disabled={!apiToken}>
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={12} className="mb-0" style={{ paddingBottom: "0px !important" }} controlId="hideWidgetOrStreamerMode">
            <ButtonGroup className="overflow-hidden w-100">
              <Button variant="danger" className="rounded-0" onClick={() => setHideWidget(true)}>
                <FontAwesomeIcon icon={faEyeSlash} />
                <span className="pl-1">Hide Widget</span>
              </Button>
              {!hideStreamerModeToggle && (
                <StreamerModeButton variant="light" className="rounded-0" />
              )}
            </ButtonGroup>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
};

export default useStreamerMode;
