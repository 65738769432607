import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import TwitchSignUp from "./Twitch/SignUp";
import YouTubeSignUp from "./YouTube/SignUp";

const SignUp = ({ children, eventNameBase64, signIn, ...props }) => {
  return (
    <ButtonGroup {...props}>
      <Button disabled variant={signIn ? "dark" : "light"} className="font-weight-normal">
        {signIn ? "Sign in with" : "Sign up with"}
      </Button>
      {children ? children : (
        <>
          <TwitchSignUp variant={signIn ? "dark" : "light"} eventNameBase64={eventNameBase64} />
          <YouTubeSignUp variant={signIn ? "dark" : "light"} eventNameBase64={eventNameBase64} />
        </>
      )}
    </ButtonGroup>
  );
};

export default SignUp;
