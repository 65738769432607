import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar } from "react-bootstrap";
import styles from "./Header.module.scss";
import Logo from "../../logo.svg";
import { useAuthentication } from "../../hooks/useAuthentication";
import useStreamerMode from "../../hooks/useStreamerMode";
import useEventName from "../../hooks/Events/useEventName";
import CurrentUserBadge from "../CurrentUserBadge/CurrentUserBadge";
import SignUp from "../Participants/SignUp";

const Header = () => {
  const { currentUser, isLoading } = useAuthentication();
  const { streamerMode } = useStreamerMode();
  const { eventNameBase64, canJoinEvent } = useEventName();

  if (streamerMode || isLoading) {
    return <div hidden />;
  }

  if (!currentUser) {
    return (
      <SignUp
        eventNameBase64={eventNameBase64}
        signIn={!canJoinEvent}
        className="float-right mt-4 mr-4"
      />
    );
  }

  const NavLink = ({ children, to }) => (
    <LinkContainer to={to}>
      <Nav.Link className={styles.navLink}>{children}</Nav.Link>
    </LinkContainer>
  );

  return (
    <header className={styles.header}>
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <LinkContainer to="/">
          <Navbar.Brand href="#">
            <img className={styles.logo} src={Logo} alt="Carper Creative" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="events">
              events
            </NavLink>
          </Nav>
          <Nav className="navbar-right">
            {currentUser ? (
                <CurrentUserBadge />
              ) : (
                <SignUp
                  eventNameBase64={eventNameBase64}
                  signIn={!canJoinEvent}
                />
              )
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
