import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Unauthorised from "../../../components/Unauthorised/Unauthorised";

const Index = () => {
  const { search } = useLocation();

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { title, message } = useMemo(
    () => ({
      title: urlSearchParams.get('title')?.toString(),
      message: urlSearchParams.get('message')?.toString(),
    }),
    [urlSearchParams],
  );

  if (message) {
    return (
      <Unauthorised title={title}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Unauthorised>
    );
  }

  return <Unauthorised title={title} />
};

export default Index;
