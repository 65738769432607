import { QueryClient } from "react-query";
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental'
import axios from "axios";
import setupParticipants from "./mutations/participants";

const setup = () => {
  const useCache = true;

  axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

  const queryClient = new QueryClient();

  const queryFn = async ({ queryKey }) => {
    const url = queryKey
      .filter(e => typeof e === "string" && e)
      .map(encodeURIComponent)
      .join("/");
    const { params } = queryKey.filter(e => typeof e === "object" && e)[0] || {};

    console.debug("Querying API...", { url, params });
    const { data } = await axios.get("/" + url, { params });
    console.debug("API returned!", { data });

    return data;
  };

  setupParticipants(queryClient);

  queryClient.setDefaultOptions({
    queries: {
      staleTime: 10 * 60 * 1000,
      cacheTime: useCache ? 1000 * 60 * 60 * 24 : 0,
      queryFn,
      retry: (failureCount, { response },) => (
        failureCount < 3 && !(response?.status > 399 && response?.status < 500)
      ),
    },
  });

  const localStoragePersistor = createAsyncStoragePersistor({ storage: window.localStorage });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor
  });

  return queryClient;
};

export default setup;
