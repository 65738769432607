import React, { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faAward } from "@fortawesome/free-solid-svg-icons";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { useQuery } from "react-query";
import collect from "collect.js";
import styles from "./View.module.scss";
import useEventName from "../../../hooks/Events/useEventName";
import { useAuthentication } from "../../../hooks/useAuthentication";
import ParticipantLeaderboardCard from "../../../components/Participants/Leaderboard/Card";

const PsnTrophiesTable = ({ psnTrophies }) => {
  if (!psnTrophies?.length) {
    return <div hidden />;
  }

  return (
    <Table variant="dark" striped borderless responsive hover>
      <tbody>
        {psnTrophies.map((trophy, index) => (
          <tr key={index}>
            <td>
              <Image
                src={trophy.title?.titleIconUrl}
                className={styles.trophyImage}
              />
              {trophy.title?.name}
            </td>
            <td>
              <Image
                src={trophy.iconUrl}
                className={styles.trophyImage}
              />
              {trophy.name}
            </td>
            <td>{trophy.weight}pts</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const XboxLiveAchievementsTable = ({ xboxLiveAchievements }) => {
  if (!xboxLiveAchievements?.length) {
    return <div hidden />;
  }

  return (
    <Table variant="dark" striped borderless responsive hover>
      <tbody>
        {xboxLiveAchievements.map((achievement, index) => (
          <tr key={index}>
            <td>{achievement.title?.name}</td>
            <td>{achievement.name}</td>
            <td>{achievement.weight}pts</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const EventParticipant = () => {
  const { eventName } = useEventName();
  const { participantId } = useParams();
  const { currentUser: { participant } = {} } = useAuthentication();

  const { data: { leaderboard } = {} } = useQuery(
    ["event", eventName, "leaderboard"],
  );

  const [tab, setTab] = useState("PSN");

  const leaderboardParticipant = useMemo(
    () => collect(leaderboard || []).firstWhere("id", "===", participantId),
    [participantId, leaderboard],
  );

  const isCurrentPartiticpant = useMemo(
    () => participantId === participant?.id,
    [participantId, participant?.id],
  );

  const { psnTrophies, xboxLiveAchievements } = leaderboardParticipant || {};

  return (
    <>
      <Jumbotron>
        <Container>
          {isCurrentPartiticpant && (
            <Button
              as={Link}
              className="float-right"
              variant="dark"
              to={`/events/${encodeURIComponent(eventName)}/widget?streamerMode=true`}
            >
              Stream Widget
            </Button>
          )}
          <h1>
            <span>Leaderboard / </span>
            <Link to={`/events/${encodeURIComponent(eventName)}`} className="text-light">
              {eventName}
            </Link>
          </h1>
        </Container>
      </Jumbotron>
      <Container>
        <ParticipantLeaderboardCard
          eventName={eventName}
          participant={leaderboardParticipant}
        />
        <ButtonGroup className="mt-4 mb-2">
          <Button variant="dark" onClick={() => setTab("PSN")}>
            <FontAwesomeIcon icon={faTrophy} />
            <span> PlayStation</span>
          </Button>
          <Button variant="success" onClick={() => setTab("XBOX")}>
            <FontAwesomeIcon icon={faAward} />
            <span> Xbox Live</span>
          </Button>
        </ButtonGroup>
        {tab === "PSN" && <PsnTrophiesTable psnTrophies={psnTrophies} />}
        {tab === "XBOX" && <XboxLiveAchievementsTable xboxLiveAchievements={xboxLiveAchievements} />}
      </Container>
    </>
  );
};

export default EventParticipant;
