import React from "react";
import { useQuery } from "react-query";
import useStreamerMode, { StreamerModeWidget } from "../../../hooks/useStreamerMode";
import useEventName from "../../../hooks/Events/useEventName";
import { useAuthentication } from "../../../hooks/useAuthentication";
import ParticipantLeaderboardCard from "../../../components/Participants/Leaderboard/Card";

const EventParticipantWidget = () => {
  const { eventName } = useEventName();
  const { currentUser } = useAuthentication();
  const { streamerMode, refreshRateMs, fontFamily } = useStreamerMode();

  useQuery({
    queryKey: ["event", eventName, "leaderboard"],
    refetchInterval: streamerMode ? refreshRateMs || undefined : undefined,
  });

  return (
    <>
      <StreamerModeWidget
        style={{ fontFamily }}
        hideStreamerModeToggle
      />
      <ParticipantLeaderboardCard
        eventName={eventName}
        participant={currentUser?.participant}
        style={{ fontFamily }}
        hideImage
      />
    </>
  );
};

export default EventParticipantWidget;
