import React from "react";
import { Link } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useAuthentication } from "../../hooks/useAuthentication";
import RequiresAuthentication from "../../components/Unauthorised/RequiresAuthentication";

const IndexEvents = () => {
  const { currentUser: { participant } = {} } = useAuthentication();

  return (
    <RequiresAuthentication>
      <Jumbotron>
        <Container>
          <h1>Your Events</h1>
        </Container>
      </Jumbotron>
      <Container>
        <Row>
          <Col xs={12}>
            <Table variant="dark" striped borderless responsive hover>
              <tbody>
                {participant?.eventNames.map(eventName => (
                  <tr key={eventName}>
                    <td>
                      <Link className="text-secondary" to={`/events/${encodeURIComponent(eventName)}`}>
                        {eventName}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </RequiresAuthentication>
  );
};

export default IndexEvents;
