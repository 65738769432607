import React, { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useAuthentication } from "../../hooks/useAuthentication";

const SignIn = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { setToken, setProvider } = useAuthentication();

  const token = useMemo(
    () => new URLSearchParams(hash.substr(1)).get("access_token"),
    [hash]
  );

  useEffect(() => {
    if (!token) {
      return;
    }

    setToken(token);
    setProvider("twitch");
    navigate("/");
  }, [setToken, setProvider, token, navigate]);

  return (
    <Loading>
      {token ? (
        <h3>Finalising authentication...</h3>
      ) : (
        <h3 className="text-danger">Authentication problem, please refresh!</h3>
      )}
    </Loading>
  );
};

export default SignIn;
