import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import TwitchGlitch from "../../../assets/twitch-brand-assets/glitch/Purple/TwitchGlitchPurple.svg";
import TwitchWorkmarkLight from "../../../assets/twitch-brand-assets/wordmark/Wordmark/White/Twitch-Wordmark-White.svg";
import TwitchWorkmarkDark from "../../../assets/twitch-brand-assets/wordmark/Wordmark/Twitch Purple/Twitch_UnextrudedWordmarkPurple.svg";

const SignUp = ({ children, eventNameBase64, signIn, signUp, ...props}) => {
  const twitchSignUpUrl = useMemo(() => {
    const { REACT_APP_API_ROOT } = process.env;
    const url = new URL(`${REACT_APP_API_ROOT || "localhost:3100"}/auth/twitch/participant`);

    if (!signIn && eventNameBase64) {
      url.searchParams.set("eventName", eventNameBase64);
    }

    return url.toString();
  }, [signIn, eventNameBase64]);

  return (
    <Button as="a" href={twitchSignUpUrl} {...props}>
      <Image src={TwitchGlitch} style={{ height: 30 }} />
      <Image src={props.variant === "dark" ? TwitchWorkmarkLight : TwitchWorkmarkDark} style={{ height: 30 }} className="pl-2" />
      {(signIn || signUp) && (
        <span className="ml-2">
          {signIn && "Sign in with "}
          {signUp && "Sign up with "}
        </span>
      )}
    </Button>
  );
};

export default SignUp;
