import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("participant");
  };

  queryClient.setMutationDefaults("addParticipant", {
    mutationFn: async ({ participant }) => {
      const { data } = await axios.post("participant", participant);

      onSuccess();

      return data;
    },
  });

  queryClient.setMutationDefaults("updateParticipant", {
    mutationFn: async ({ participant, me = false }) => {
      console.debug({ participant });

      const { data } = await axios.put(
        `participant/${encodeURIComponent(participant.id)}`,
        participant,
      );

      onSuccess();
      queryClient.invalidateQueries(["participant", participant.id]);

      if (me) {
        queryClient.invalidateQueries(["participant", "twitch", "me"]);
      }

      return data;
    },
  });

  queryClient.setMutationDefaults("updateParticipantApiToken", {
    mutationFn: async ({ participant, me = false }) => {
      console.debug({ participant });

      const { data } = await axios.put(
        `participant/${encodeURIComponent(participant.id)}/generateApiToken`,
        participant,
      );

      onSuccess();
      queryClient.invalidateQueries(["participant", participant.id]);

      if (me) {
        queryClient.invalidateQueries(["participant", "twitch", "me"]);
      }

      return data;
    },
    retry: 0
  });

  return queryClient;
};

export default setup;
