import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

const AuthenticationContext = createContext({ token: null, apiToken: null });

export const useAuthentication = () => {
  const navigate = useNavigate();

  const { setToken, token, apiToken, provider, ...context } = useContext(AuthenticationContext);
  const [enabled, setEnabled] = useState(!!(token || apiToken));

  const { data: currentUser, isLoading } = useQuery({
    queryKey: ["participant", provider, "me"],
    enabled: enabled,
    retry: true,
    onError: ({ response: { status } = {} }) => {
      if ([404, 401, 502].includes(status)) {
        console.error("Error while fetching current user", status);

        navigate('/401');
      }
    }
  });

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      const authorization = token ? `Bearer ${provider} ${token}` : apiToken ? `Bearer-Bot ${apiToken}` : null;

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: authorization,
        }
      };
    });

    setEnabled(!!(token || apiToken));

    return () => {
      axios.interceptors.request.eject(interceptor);

      setEnabled(false);
    };
  }, [token, apiToken, provider]);

  return {
    setToken,
    currentUser,
    isLoading,
    isAdmin: currentUser?.apiUser?.isAdmin,
    twitchId: currentUser?.apiUser?.twitchId,
    token,
    apiToken,
    ...context
  };
};

const AuthenticationProvider = ({ children }) => {
  const { search } = useLocation();

  const [provider, setProvider] = useState(window.sessionStorage.getItem("provider"));
  const setProviderAndSessionStorage = useCallback((value) => {
    setProvider(value);

    window.sessionStorage.setItem("provider", value);
  }, [setProvider]);

  const [token, setToken] = useState(window.sessionStorage.getItem("token"));
  const setTokenAndSessionStorage = useCallback((value) => {
    setToken(value);

    window.sessionStorage.setItem("token", value);
  }, [setToken]);

  const apiToken = useMemo(() => new URLSearchParams(search).get('apiToken'), [search]);

  return (
    <AuthenticationContext.Provider value={{
      token,
      setToken: setTokenAndSessionStorage,
      apiToken,
      provider,
      setProvider: setProviderAndSessionStorage,
    }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
